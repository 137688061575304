package store

/**
 * no image uploaded - no detection yet
 * Image Uploaded - no detection yet
 * Image Uploaded - detection running
 * Image Uploaded - detection done
 */
import it.neckar.bioexp.rest.detect.ECDetectionResult
import it.neckar.bioexp.rest.detect.RBCDetectionResult
import it.neckar.open.collections.IntArray2
import it.neckar.open.kotlin.serializers.IntArray2Serializer
import it.neckar.open.unit.other.Sorted
import kotlinx.serialization.Serializable
import web.file.FileList

/**
 * States for the image upload and detection process.
 */
sealed class ImageState {
}

@Serializable
data object OpcuaState : ImageState() {
}
/**
 * Default state when no upload and no detection have occurred.
 */
@Serializable
data object DefaultStateSOP1 : ImageState() {
}

/**
 * Default state when no upload and no detection have occurred.
 */
@Serializable
data object DefaultStateSOP2 : ImageState() {
}

/**
 * Default state when no upload and no detection have occurred.
 */
@Serializable
data object DefaultStateSOP3 : ImageState() {
}

/**
 * Default state when no upload and no detection have occurred.
 */
@Serializable
data object DefaultStateSOP5 : ImageState() {
}

/**
 * State when the image is being read by the browser.
 */
@Serializable
data object ImageSelectedForUploadSOP1 : ImageState() {
}

/**
 * State when the image is being read by the browser.
 */
@Serializable
data object ImageSelectedForUploadSOP2 : ImageState() {
}

/**
 * State when the image is being read by the browser.
 */
@Serializable
data object ImageSelectedForUploadSOP5 : ImageState() {
}

/**
 * State when the image has been uploaded and is ready for detection.
 */
@Serializable
data class UploadedStateSOP1(
  /**
   * Name of the file
   */
  val filename: String,

  /**
   * Filepath of the uploaded file
   */
  val filepath: String,
) : ImageState() {
}

/**
 * State when the image has been uploaded and is ready for detection.
 */
@Serializable
data class UploadedStateSOP2(
  /**
   * Name of the file
   */
  val filename: String,

  /**
   * Filepath of the uploaded file
   */
  val filepath: String,
) : ImageState() {
}

/**
 * State when images are being uploaded.
 */
data class UploadingStateSOP3(

  /**
   * The name of the file currently being uploaded.
   */
  val currentlyUploadingFileName: String,

  /**
   * The total number of files to upload.
   */
  val numberOfFilesToUpload: Int,

  /**
   * The list of files that have already been uploaded.
   */
  val alreadyUploadedFiles: List<String>,
) : ImageState() {
}

data class ArrangingFilesSOP3(

  /**
   * The total number of files to upload.
   */
  val numberOfFilesToArrange: Int,

  val filesToArrange: FileList,
) : ImageState() {
}

@Serializable
data class UploadedStateSOP3(

  /**
   * Filepath of the uploaded file
   */
  val filepaths: List<String>,

  val filenames: List<String>,
) : ImageState() {
}
@Serializable
data class UploadedStateSOP5(
  val filename: String,
  val filepath: String,
) : ImageState() {
}
/**
 * State when the detection process is running for an uploaded image.
 */
@Serializable
data class DetectionRunningSOP1Rbc(
  /**
   * Filepath of the uploaded file
   */
  val filepath: String,
) : ImageState() {
}
/**
 * State when the detection process is running for an uploaded image.
 */
@Serializable
data class DetectionRunningSOP1Ec(
  /**
   * Filepath of the uploaded file
   */
  val filepath: String,
) : ImageState() {
}

/**
 * State when the detection process is running for an uploaded image.
 */
@Serializable
data class DetectionRunningSOP2(
  /**
   * Filepath of the uploaded file
   */
  val filepath: String,
) : ImageState() {
}

/**
 * State when images are being detected.
 */
data class DetectionRunningSOP3(

  /**
   * The name of the file currently being detected.
   */
  val currentlyDetectingFileName: String,

  /**
   * The total number of files to detect.
   */
  val numberOfFilesToDetect: Int,

  /**
   * The list of DetectionResult of files that have already been detected.
   */
  val alreadyDetectedFiles: MutableList<RBCDetectionResult>,
) : ImageState() {
}

/**
 * State when the detection process is running for an uploaded image.
 */
@Serializable
data class DetectionRunningSOP5(
  /**
   * Filepath of the uploaded file
   */
  val filepath: String,
) : ImageState() {
}


/**
 * State when the detection process for an image has been completed.
 */
@Serializable
data class DetectedStateSOP1(
  /**
   * Detection result for the image EC.
   */
  val ECDetectionResult: ECDetectionResult,

  /**
   * Detection result for the image RBC.
   */
  val RBCetectionResult: RBCDetectionResult,

  /**
   * Base64 data for the image
   */
  val imageDataRBC: String?,

  val imageDataEC: String?,

  val bitmapDrawnImage: String?,

  /**
   * Name of the file
   */
  val filename: String,

  /**
   * Filepath of the uploaded file
   */
  val filepath: String,
  val ecBitmap: @Serializable(with = IntArray2Serializer::class) IntArray2,
) : ImageState() {
}

/**
 * State when the detection process for an image has been completed.
 */
@Serializable
data class DetectedStateSOP2(
  /**
   * Detection result for the image.
   */
  val RBCDetectionResult: RBCDetectionResult,

  /**
   * Base64 data for the image
   */
  val imageData: String?,

  /**
   * Name of the file
   */
  val filename: String,

  /**
   * Filepath of the uploaded file
   */
  val filepath: String,
) : ImageState() {
}

/**
 * State when the detection process for multiple images has been completed.
 *
 * Each of the properties must have the same size and the same order.
 */
data class DetectedStateSOP3(
  /**
   * List of detection results for the images.
   */
  val detectionResults: @Sorted(by = "capture time") List<RBCDetectionResult>,

  /**
   * Base64 data for the images.
   */
  val imageData: List<String>?,

  /**
   * List of filenames.
   */
  val filenames: List<String>,

  /**
   * List of filepaths for the uploaded files.
   */
  val filePaths: List<String>,
) : ImageState() {
}

/**
 * State when the detection process for an image has been completed.
 */
@Serializable
data class DetectedStateSOP5(
  /**
   * Detection result for the image EC.
   */
  val detectionResult: ECDetectionResult,


  /**
   * Base64 data for the image
   */
  val imageData: String?,

  /**
   * Name of the file
   */
  val filename: String,

  /**
   * Filepath of the uploaded file
   */
  val filepath: String,
  val ecBitmap: @Serializable(with = IntArray2Serializer::class) IntArray2,
) : ImageState() {
}
