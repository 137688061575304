package it.neckar.projects.bioexp

import it.neckar.projects.common.Port

/**
 * Contains the ports for the BioExP project.
 * Will be copied to the `bioexp-rest-api` project by the [it.neckar.gradle.project.ProjectConfigurationPlugin]
 */
object BioExPPorts {
  object Ui {
    /**
     * Running with dev-server by calling `gradle jsRun`
     * See: `internal/closed/bioexp/bioexp-ui/webpack.config.d/dev-server-config.js`
     */
    val devServer: Port = Port(9081)

    /**
     * When executing from the docker container.
     *
     * Exposed `docker-compose.yml` //TODO!!!
     */
    val docker: Port = Port(8080)
  }

  /**
   * Port for the detection service
   */
  val detectionService: Port = Port(8085)
  val storageService: Port = Port(8086)
  val preAnnotationService: Port = Port(8087)
  val cameraService: Port = Port(8088)
  val hubService: Port = Port(8090)
}
