package services

import com.meistercharts.js.CanvasFontMetricsCalculatorJS.Companion.logger
import it.neckar.bioexp.rest.detect.RBCDetectionResult
import it.neckar.bioexp.rest.message.CameraCaptureResult
import it.neckar.bioexp.rest.message.CameraImageResponse
import it.neckar.bioexp.rest.message.CameraSettings
import it.neckar.bioexp.rest.message.CameraSettingsResponse
import it.neckar.bioexp.rest.message.DetectRequest
import it.neckar.bioexp.rest.message.DetectResponseEC
import it.neckar.bioexp.rest.message.DetectResponseRBC
import it.neckar.bioexp.rest.message.FeedbackUploadRequest
import it.neckar.bioexp.rest.message.FileUploadRequest
import it.neckar.bioexp.rest.message.FileUploadResponse
import it.neckar.common.redux.dispatch
import it.neckar.commons.kotlin.js.toByteArray
import it.neckar.open.kotlin.lang.truncateToLength
import it.neckar.open.unit.other.pct
import it.neckar.react.common.*
import it.neckar.react.common.toast.*
import it.neckar.rest.jwt.JwtToken
import it.neckar.rest.jwt.JwtTokenPair
import it.neckar.user.UserLoginName
import js.buffer.ArrayBuffer
import store.ArrangingFilesSOP3
import store.DefaultStateSOP1
import store.DefaultStateSOP2
import store.DefaultStateSOP3
import store.DefaultStateSOP5
import store.DetectedStateSOP1
import store.DetectedStateSOP2
import store.DetectedStateSOP3
import store.DetectedStateSOP5
import store.DetectionRunningSOP1Ec
import store.DetectionRunningSOP1Rbc
import store.DetectionRunningSOP2
import store.DetectionRunningSOP3
import store.DetectionRunningSOP5
import store.ImageSelectedForUploadSOP1
import store.ImageSelectedForUploadSOP2
import store.ImageSelectedForUploadSOP5
import store.OpcuaState
import store.SetThresholdState
import store.UploadedStateSOP1
import store.UploadedStateSOP2
import store.UploadedStateSOP3
import store.UploadedStateSOP5
import store.UploadingStateSOP3
import store.actions.ImageAction
import store.actions.LoggedInAction
import store.actions.ThresholdAction
import web.events.EventHandler
import web.file.File
import web.file.FileList
import web.file.FileReader
import kotlin.io.encoding.Base64

// 10MB max file size
const val MAX_ALLOWED_FILE_SIZE: Int = 10000000

object UiActions {

  /**
   * Runs object detection on the given image using the specified file path.
   *
   * @param filepath The file path of the image to run object detection on.
   * @param thresholdValue The threshold value to use for object detection.
   * @param originalFileName The original file name of the image.
   */
  suspend fun runDetectionSOP1(originalFileName: String, filepath: String, thresholdValue: @pct Double) {
    delayIfSlowUiEnabled()

    if (thresholdValue < 0.01 || thresholdValue > 1.0) {
      notifyError("Invalid Threshold value", "Threshold value cannot be smaller than 0.01 or larger than 1, setting to default value 0.5")
    }


    val request = DetectRequest(
      path = filepath,
      threshold = if (thresholdValue < 0.01 || thresholdValue > 1.0) 0.5 else thresholdValue,
      returnBoundingBoxImage = true
    )

    dispatch(ImageAction(DetectionRunningSOP1Ec(filepath)))

    try {
      when (val detectResponseEC = UiServices.detectionServiceClient.detectEC(request)) {
        is DetectResponseEC.Success -> {
          try {
            dispatch(ImageAction(DetectionRunningSOP1Rbc(filepath)))
            when (val detectResponseRBC = UiServices.detectionServiceClient.detectRBCSOP1(request)) {
              is DetectResponseRBC.Success -> {
                val boundingBoxImagedataRBC = "data:image/jpeg;base64, " + detectResponseRBC.detections.boundingBoxImage?.let { Base64.encode(it) }
                val bitmapDrawnImage = "data:image/jpeg;base64, " + detectResponseEC.detections.bitmapDrawnImage?.let { Base64.encode(it) }
                val boundingBoxImagedataEC = "data:image/jpeg;base64, " + detectResponseEC.detections.boundingBoxImage?.let { Base64.encode(it) }
                dispatch(
                  ImageAction(
                    DetectedStateSOP1(
                      ECDetectionResult = detectResponseEC.detections, RBCetectionResult = detectResponseRBC.detections, imageDataRBC = boundingBoxImagedataRBC, imageDataEC = boundingBoxImagedataEC, bitmapDrawnImage = bitmapDrawnImage, filename = originalFileName, filepath = filepath, ecBitmap = detectResponseEC.detections.ecBitmap
                    )
                  )
                )
              }

              is DetectResponseRBC.Failure -> {
                dispatch(ImageAction(DefaultStateSOP1))
                notifyError("Detection Run Failed", "Detection Service could not be reached")
              }
            }
          } catch (e: Exception) {
            dispatch(ImageAction(DefaultStateSOP1))
            notifyError("Error", "${e.message?.truncateToLength(500)}")
          }
        }

        is DetectResponseEC.Failure -> {
          dispatch(ImageAction(DefaultStateSOP1))
          notifyError("Detection Run Failed", "Detection Service could not be reached")
        }
      }
    } catch (e: Exception) {
      dispatch(ImageAction(DefaultStateSOP1))
      notifyError("Error", "${e.message?.truncateToLength(500)}")
    }
  }

  /**
   * Uploads the given file for SOP1.
   *
   * @param selectedFileBuffer The selected file data as an ArrayBuffer.
   * @param file The File object representing the uploaded file.
   */
  suspend fun uploadFileSOP1(selectedFileBuffer: ArrayBuffer, file: File) {
    delayIfSlowUiEnabled()

    val byteArray = selectedFileBuffer.toByteArray()

    val request = FileUploadRequest(
      fileName = file.name,
      fileData = byteArray
    )

    try {
      when (val uploadResponse = UiServices.storageServiceClient.upload(request)) {
        is FileUploadResponse.Success -> dispatch(ImageAction(UploadedStateSOP1(file.name, uploadResponse.filePath)))
        is FileUploadResponse.Failure -> {
          dispatch(ImageAction(DefaultStateSOP1))
          notifyError("Image Upload Failed", "Image Upload Service could not be reached")
        }
      }
    } catch (e: Exception) {
      dispatch(ImageAction(DefaultStateSOP1))
      notifyError("Error", "${e.message?.truncateToLength(500)}")
    }
  }

  /**
   * Loads a file for SOP1 and triggers the upload process.
   *
   * @param file The File object representing the file to be loaded.
   */
  fun loadFileSOP1(file: File) {
    if (file.size > MAX_ALLOWED_FILE_SIZE) {
      notifyError("File ${file.name} too large", "The file you selected is too large. Please select a file smaller than 10MB.")
      dispatch(ImageAction(DefaultStateSOP1))
      return
    }

    val reader = FileReader()
    setImageSelectedForUploadSOP1()

    // Read the file as an ArrayBuffer, triggers reader.onload
    reader.readAsArrayBuffer(file)

    // triggered when the file has been fully read
    reader.onload = EventHandler {
      val readerResult = reader.result as ArrayBuffer
      launchAndNotify {
        uploadFileSOP1(readerResult, file)
      }
    }
  }

  /**
   * Sets the state so that an image is selected for upload in SOP1.
   */
  fun setImageSelectedForUploadSOP1() {
    try {
      dispatch(ImageAction(ImageSelectedForUploadSOP1))
    } catch (e: Exception) {
      dispatch(ImageAction(DefaultStateSOP1))
      notifyError("Error", "${e.message?.truncateToLength(500)}")
    }
  }

  /**
   * Sets SOP1 to its default state by resetting the uploaded image to none.
   */
  fun setSOP1DefaultState() {
    dispatch(ImageAction(DefaultStateSOP1))
  }

  /**
   * Runs object detection on the given image using the specified file path.
   *
   * @param filepath The file path of the image to run object detection on.
   * @param thresholdValue The threshold value to use for object detection.
   * @param originalFileName The original file name of the image.
   */
  suspend fun runDetectionSOP2(originalFileName: String, filepath: String, thresholdValue: @pct Double) {
    delayIfSlowUiEnabled()

    if (thresholdValue < 0.01 || thresholdValue > 1.0) {
      notifyError("Invalid Threshold value", "Threshold value cannot be smaller than 0.01 or larger than 1, setting to default value 0.5")
    }

    val request = DetectRequest(
      path = filepath,
      threshold = if (thresholdValue < 0.01 || thresholdValue > 1.0) 0.5 else thresholdValue,
      returnBoundingBoxImage = true
    )

    dispatch(ImageAction(DetectionRunningSOP2(filepath)))

    try {
      when (val detectResponse = UiServices.detectionServiceClient.detectRBC(request)) {
        is DetectResponseRBC.Success -> {
          val boundingBoxImagedata = "data:image/jpeg;base64, " + detectResponse.detections.boundingBoxImage?.let { Base64.encode(it) }
          dispatch(ImageAction(DetectedStateSOP2(detectResponse.detections, boundingBoxImagedata, originalFileName, filepath)))
        }

        is DetectResponseRBC.Failure -> {
          dispatch(ImageAction(DefaultStateSOP2))
          notifyError("Detection Run Failed", "Detection Service could not be reached")
        }
      }
    } catch (e: Exception) {
      dispatch(ImageAction(DefaultStateSOP2))
      notifyError("Error", "${e.message?.truncateToLength(500)}")
    }
  }

  /**
   * Uploads the given file for SOP2.
   *
   * @param selectedFileBuffer The selected file data as an ArrayBuffer.
   * @param file The File object representing the uploaded file.
   */
  suspend fun uploadFileSOP2(selectedFileBuffer: ArrayBuffer, file: File) {
    delayIfSlowUiEnabled()

    val byteArray = selectedFileBuffer.toByteArray()

    val request = FileUploadRequest(
      fileName = file.name,
      fileData = byteArray
    )

    try {
      when (val uploadResponse = UiServices.storageServiceClient.upload(request)) {
        is FileUploadResponse.Success -> dispatch(ImageAction(UploadedStateSOP2(file.name, uploadResponse.filePath)))
        is FileUploadResponse.Failure -> {
          dispatch(ImageAction(DefaultStateSOP2))
          notifyError("Image Upload Failed", "Image Upload Service could not be reached")
        }
      }
    } catch (e: Exception) {
      dispatch(ImageAction(DefaultStateSOP2))
      notifyError("Error", "${e.message?.truncateToLength(500)}")
    }
  }

  /**
   * Loads a file for SOP2 and triggers the upload process.
   *
   * @param file The File object representing the file to be loaded.
   */
  fun loadFileSOP2(file: File) {
    if (file.size > MAX_ALLOWED_FILE_SIZE) {
      notifyError("File ${file.name} too large", "The file you selected is too large. Please select a file smaller than 10MB.")
      dispatch(ImageAction(DefaultStateSOP2))
      return
    }

    val reader = FileReader()
    setImageSelectedForUploadSOP2()

    // Read the file as an ArrayBuffer, triggers reader.onload
    reader.readAsArrayBuffer(file)

    // triggered when the file has been fully read
    reader.onload = EventHandler {
      val readerResult = reader.result as ArrayBuffer
      launchAndNotify {
        uploadFileSOP2(readerResult, file)
      }
    }
  }


  /**
   * Sets the state so that an image is selected for upload in SOP2.
   */
  fun setImageSelectedForUploadSOP2() {
    try {
      dispatch(ImageAction(ImageSelectedForUploadSOP2))
    } catch (e: Exception) {
      dispatch(ImageAction(DefaultStateSOP2))
      notifyError("Error", "${e.message?.truncateToLength(500)}")
    }
  }

  /**
   * Sets SOP2 to its default state by resetting the uploaded image to none.
   */
  fun setSOP2DefaultState() {
    dispatch(ImageAction(DefaultStateSOP2))
  }

  /**
   * Runs object detection on multiple images for SOP3.
   *
   * @param filepaths The list of file paths for the images to run object detection on.
   * @param filenames The list of file names for the images to run object detection on.
   * @param thresholdValue The threshold value to use for object detection.
   */
  suspend fun runDetectionSOP3(filepaths: List<String>, filenames: List<String>, thresholdValue: @pct Double) {
    delayIfSlowUiEnabled()

    if (thresholdValue < 0.01 || thresholdValue > 1.0) {
      notifyError("Invalid Threshold value", "Threshold value cannot be smaller than 0.01 or larger than 1, setting to default value 0.5")
    }
    val requests: MutableList<DetectRequest> = mutableListOf()

    filepaths.forEach {
      requests.add(
        DetectRequest(
          path = it,
          threshold = if (thresholdValue < 0.01 || thresholdValue > 1.0) 0.5 else thresholdValue,
          returnBoundingBoxImage = true
        )
      )
    }

    val imageData = mutableListOf<String>()
    val detections = mutableListOf<RBCDetectionResult>()

    requests.forEachIndexed { index, request ->
      try {
        dispatch(ImageAction(DetectionRunningSOP3(filenames[index], filepaths.size - 1, detections)))
        when (val detectResponse = UiServices.detectionServiceClient.detectRBCSOP3(request)) {
          is DetectResponseRBC.Success -> {
            detections.add(detectResponse.detections)
            val boundingBoxImagedata = "data:image/jpeg;base64, " + detectResponse.detections.boundingBoxImage?.let { Base64.encode(it) }
            imageData.add(boundingBoxImagedata)
          }

          is DetectResponseRBC.Failure -> {
            dispatch(ImageAction(DefaultStateSOP3))
            notifyError("Detection Run Failed", "Detection Service could not be reached")
          }
        }
      } catch (e: Exception) {
        dispatch(ImageAction(DefaultStateSOP3))
        notifyError("Error", "${e.message?.truncateToLength(500)}")
      }
    }

    dispatch(ImageAction(DetectedStateSOP3(detections, imageData, filenames, filepaths)))
  }

  /**
   * Upload files and keep track of already uploaded to facilitate a progress bar
   *
   * @param fileList The list of files to be uploaded.
   * @param sortedReaderResults The list of ArrayBuffer containing the file data.
   */
  suspend fun uploadFilesSOP3(fileList: List<File>, sortedReaderResults: List<ArrayBuffer>) {
    delayIfSlowUiEnabled()
    val requests: MutableList<FileUploadRequest> = mutableListOf()

    fileList.forEachIndexed { index, file ->
      requests.add(
        FileUploadRequest(
          fileName = file.name,
          fileData = sortedReaderResults[index].toByteArray()
        )
      )
    }

    val paths = mutableListOf<String>()

    requests.forEach { request ->
      try {
        dispatch(ImageAction(UploadingStateSOP3(request.fileName, fileList.size, paths)))
        when (val uploadResponse = UiServices.storageServiceClient.upload(request)) {
          is FileUploadResponse.Success -> {
            paths.add(uploadResponse.filePath)
          }

          is FileUploadResponse.Failure -> {
            dispatch(ImageAction(DefaultStateSOP3))
            notifyError("Image Upload Failed", "Image Upload Service could not be reached")
          }
        }
      } catch (e: Exception) {
        dispatch(ImageAction(DefaultStateSOP3))
        notifyError("Error", "${e.message?.truncateToLength(500)}")
      }
    }

    dispatch(
      ImageAction(
        UploadedStateSOP3(
          paths,
          fileList.map { it.name }
        )
      )
    )

  }

  /**
   * Loads a list of files for SOP3 and triggers the upload process.
   *
   * @param files The FileList containing the files to be loaded.
   */
  fun loadFilesSOP3(files: FileList) {
    if (files.length < 2) {
      notifyError("Not enough images selected", "Please select at least 2 images")
      dispatch(ImageAction(DefaultStateSOP3))
      return
    }

    dispatch(ImageAction(ArrangingFilesSOP3(files.length, files)))

  }

  fun uploadFilesSOP3(files: List<File>) {
    dispatch(ImageAction(UploadingStateSOP3(files[0].name, files.size, emptyList())))

    // Maps to store file-to-reader and file-to-path associations
    val fileToReader: MutableMap<File, FileReader> = mutableMapOf()
    val fileToResult: MutableMap<File, ArrayBuffer> = mutableMapOf()

    // List to maintain the order in which files are processed
    val fileList = mutableListOf<File>()
    val readerList = mutableListOf<ArrayBuffer>()

    for (file in files) {
      if (file.size > MAX_ALLOWED_FILE_SIZE) {
        notifyError("File ${file.name} too large", "The file you selected is too large. Please select a file smaller than 10MB.")
        dispatch(ImageAction(DefaultStateSOP3))
        return
      }
      // Create a FileReader for each file and associate it with the file
      // One FileReader cannot be used for multiple files
      val reader = FileReader()
      fileToReader += Pair(file, reader)

      // Add the file to the list to maintain processing order
      fileList.add(file)
    }

    // Iterate through the file-to-reader map
    fileToReader.entries.forEach { fileToReaderMap ->
      // Read the file as an array buffer
      fileToReaderMap.value.readAsArrayBuffer(fileToReaderMap.key)
      fileToReaderMap.value.onload = EventHandler {
        // Asynchronously upload the file and get the path
        launchAndNotify {
          readerList.add(fileToReaderMap.value.result as ArrayBuffer)
          fileToResult += Pair(fileToReaderMap.key, fileToReaderMap.value.result as ArrayBuffer)

          if (readerList.size == fileList.size) {
            //sort to order of filelist
            val sortedReaderResults = fileList.mapNotNull { fileToResult[it] }
            uploadFilesSOP3(fileList, sortedReaderResults)
          }
        }
      }
    }
  }

  /**
   * Sets SOP3 to its default state by resetting the uploaded image to none.
   */
  fun setSOP3DefaultState() {
    dispatch(ImageAction(DefaultStateSOP3))
  }

  /**
   * Runs object detection on the given image using the specified file path.
   *
   * @param filepath The file path of the image to run object detection on.
   * @param thresholdValue The threshold value to use for object detection.
   * @param originalFileName The original file name of the image.
   */
  suspend fun runDetectionSOP5(originalFileName: String, filepath: String, thresholdValue: @pct Double) {
    delayIfSlowUiEnabled()

    if (thresholdValue < 0.01 || thresholdValue > 1.0) {
      notifyError("Invalid Threshold value", "Threshold value cannot be smaller than 0.01 or larger than 1, setting to default value 0.5")
    }


    val request = DetectRequest(
      path = filepath,
      threshold = if (thresholdValue < 0.01 || thresholdValue > 1.0) 0.5 else thresholdValue,
      returnBoundingBoxImage = true
    )

    dispatch(ImageAction(DetectionRunningSOP5(filepath)))

    try {
      when (val detectResponseEC = UiServices.detectionServiceClient.detectSOP5(request)) {
        is DetectResponseEC.Success -> {
          val boundingBoxImagedataEC = "data:image/jpeg;base64, " + detectResponseEC.detections.boundingBoxImage?.let { Base64.encode(it) }
          dispatch(
            ImageAction(
              DetectedStateSOP5(
                detectionResult = detectResponseEC.detections, imageData = boundingBoxImagedataEC, filename = originalFileName, filepath = filepath, ecBitmap = detectResponseEC.detections.ecBitmap
              )
            )
          )
        }

        is DetectResponseEC.Failure -> {
          dispatch(ImageAction(DefaultStateSOP5))
          notifyError("Detection Run Failed", "Detection Service could not be reached")
        }
      }
    } catch (e: Exception) {
      dispatch(ImageAction(DefaultStateSOP5))
      notifyError("Error", "${e.message?.truncateToLength(500)}")
    }
  }

  /**
   * Uploads the given file for SOP5.
   *
   * @param selectedFileBuffer The selected file data as an ArrayBuffer.
   * @param file The File object representing the uploaded file.
   */
  suspend fun uploadFileSOP5(selectedFileBuffer: ArrayBuffer, file: File) {
    delayIfSlowUiEnabled()

    val byteArray = selectedFileBuffer.toByteArray()

    val request = FileUploadRequest(
      fileName = file.name,
      fileData = byteArray
    )

    try {
      when (val uploadResponse = UiServices.storageServiceClient.upload(request)) {
        is FileUploadResponse.Success -> dispatch(ImageAction(UploadedStateSOP5(file.name, uploadResponse.filePath)))
        is FileUploadResponse.Failure -> {
          dispatch(ImageAction(DefaultStateSOP5))
          notifyError("Image Upload Failed", "Image Upload Service could not be reached")
        }
      }
    } catch (e: Exception) {
      dispatch(ImageAction(DefaultStateSOP5))
      notifyError("Error", "${e.message?.truncateToLength(500)}")
    }
  }

  /**
   * Loads a file for SOP5 and triggers the upload process.
   *
   * @param file The File object representing the file to be loaded.
   */
  fun loadFileSOP5(file: File) {
    if (file.size > MAX_ALLOWED_FILE_SIZE) {
      notifyError("File ${file.name} too large", "The file you selected is too large. Please select a file smaller than 10MB.")
      dispatch(ImageAction(DefaultStateSOP5))
      return
    }

    val reader = FileReader()
    setImageSelectedForUploadSOP5()

    // Read the file as an ArrayBuffer, triggers reader.onload
    reader.readAsArrayBuffer(file)

    // triggered when the file has been fully read
    reader.onload = EventHandler {
      val readerResult = reader.result as ArrayBuffer
      launchAndNotify {
        uploadFileSOP5(readerResult, file)
      }
    }
  }

  /**
   * Sets the state so that an image is selected for upload in SOP5.
   */
  fun setImageSelectedForUploadSOP5() {
    try {
      dispatch(ImageAction(ImageSelectedForUploadSOP5))
    } catch (e: Exception) {
      dispatch(ImageAction(DefaultStateSOP5))
      notifyError("Error", "${e.message?.truncateToLength(500)}")
    }
  }

  /**
   * Sets SOP5 to its default state by resetting the uploaded image to none.
   */
  fun setSOP5DefaultState() {
    dispatch(ImageAction(DefaultStateSOP5))
  }

  /**
   * Uploads feedback to the corresponding image
   *
   * @param text The feedback text
   * @param imageHash The hash of the saved image
   */
  suspend fun submitFeedback(text: String, imageHash: String) {
    val request = FeedbackUploadRequest(
      fileName = imageHash,
      feedbackText = text
    )

    try {
      when (UiServices.storageServiceClient.uploadFeedback(request)) {
        is FileUploadResponse.Success -> notifySuccess("Feedback submitted")
        is FileUploadResponse.Failure -> {
          dispatch(ImageAction(DefaultStateSOP2))
          notifyError("Submitting Feedback failed", "")
        }
      }
    } catch (e: Exception) {
      dispatch(ImageAction(DefaultStateSOP2))
      notifyError("Error", "${e.message?.truncateToLength(500)}")
    }
  }

  /**
   * TODO actually login, currently hardcoded
   * Logs in the user.
   *
   * @param loginName The user's login name.
   * @param password The user's password.
   */
  suspend fun login(loginName: UserLoginName, password: String) {
    logger.debug("Logging in $loginName")

    delayIfSlowUiEnabled()

    val hardcodedTestUsername = UserLoginName("bioexp")
    val hardcodedTestPassword = "bioexp-neckarit"

    val hardcodedTestUsernameStudents = UserLoginName("fh-aachen")
    val hardcodedTestPasswordStudents = "AnatomiePhysiologie_WS23.24"

    val passwordMatches = password == hardcodedTestPassword || password == hardcodedTestPasswordStudents
    val userMatches = loginName == hardcodedTestUsername || loginName == hardcodedTestUsernameStudents

    val tokenPair = JwtTokenPair(JwtToken("123"), JwtToken("456"))

    when (passwordMatches && userMatches) {
      true -> {
        dispatch(LoggedInAction(hardcodedTestUsername.value, hardcodedTestPassword, tokenPair))
        notifySuccess("Login successful")
      }

      false -> {
        notifyError("Login failed", "Wrong Username / Password")
      }
    }
  }

  /**
   * Sets the threshold value for object detection.
   *
   * @param threshold The threshold value to use for object detection.
   */
  fun setThreshold(threshold: @pct Double) {
    dispatch(ThresholdAction(SetThresholdState(threshold)))
  }

  fun setOpcuaState() {
    dispatch(ImageAction(OpcuaState))
  }

  /**
   * Send image request to the camera service
   */
  suspend fun captureImage(): CameraCaptureResult? {
    val image = UiServices.cameraService.captureImage()
    when (image) {
      is CameraImageResponse.Success -> {
        notifySuccess("Image captured")
        return image.data[0]
      }

      is CameraImageResponse.Failure -> {
        notifyError("Image capture failed", "Camera Service could not be reached")
        return null
      }
    }
  }

  suspend fun getSettings(): CameraSettings? {
    val settings = UiServices.cameraService.getSettings()
    when (settings) {
      is CameraSettingsResponse.Success -> {
        notifySuccess("Settings fetched")
        return settings.data
      }

      is CameraSettingsResponse.Failure -> {
        notifyError("Settings fetch failed", "Camera Service could not be reached")
        return null
      }
    }
  }

  suspend fun setSettings(settings: CameraSettings) {
    val response = UiServices.cameraService.setSettings(settings)
    when (response) {
      is CameraSettingsResponse.Success -> {
        notifySuccess("Settings set")
      }

      is CameraSettingsResponse.Failure -> {
        notifyError("Settings set failed", "Camera Service could not be reached")
      }
    }
  }
}
